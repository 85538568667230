import styled from 'styled-components';
export const Container = styled.div`
  height: 4.000vw;
  width: 19.50vw;
  background-color: #8DBFCA;
  border-bottom: 4px solid #6B98A2;
  position: relative;
  cursor: pointer;
`;

export const Label = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 0;
  width: 100%;
`;