import styled from 'styled-components';

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;
  padding: 0 5.313vw;
  padding-bottom: 14.37vw;
`;

export const Background = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: -1;
`;

export const ItemPicture = styled.img`
  width: 86.25vw;
  height: 86.25vw;
  object-fit: contain;
  margin-bottom: 2.500vw;
`;

export const ItemDetails = styled.div`
  margin-top: 5.375vw;
  flex: 1;
`;
export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 8.750vw;
  color: #343538;
  letter-spacing: 0.7px;
  line-height: 32px;
  margin-top: 7.187vw;
`;
export const Price = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 5.625vw;
  color: #6B969D;
  letter-spacing: 0.95px;
  line-height: 46px;
  text-decoration: line-through;
  margin-top: 5.938vw;
  margin-bottom: 0.9375vw;
`;
export const SalePrice = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 8.750vw;
  color: #343538;
  letter-spacing: 0.7px;
  line-height: 46px;

`;
export const SavePercentage = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 5.625vw;
  color: #666EE8;
  letter-spacing: 0.95px;
  line-height: 46px;
  margin-bottom: 8.750vw;
`;
export const Description = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #737680;
  letter-spacing: 0.35px;
  line-height: 22px;
  margin-left: 3.125vw;
`;

export const BuyNow = styled.div`
  background-color: #7DBEC8;
  height: 20.00vw;
  width: 78.44vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 4.375vw;
  color: #FFFFFF;
  letter-spacing: 2.1px;
  line-height: 16px;
  cursor: pointer;
  margin-bottom: 6.563vw;
  img {
    margin-right: 4.375vw;
  }
`;

export const AddToFavorites = styled.div`
  background-color: white;
  border: 5px solid #7DBEC8;
  height: calc(20.00vw - 10px);
  width: calc(78.44vw - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 4.375vw;
  color: #7DBEC8;
  letter-spacing: 2.1px;
  line-height: 16px;
  cursor: pointer;
`;

export const ItemPictureMinized = styled.img`
  object-fit: contain;
  width: 10.94vw;
  height: 10.94vw;
  background-color: #D5D2CD;
  margin-right: 2.813vw;
  margin-bottom: 2.813vw;
`;

export const ItemPicturesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  > div {
    width: 78.44vw;
    display: flex;
    flex-wrap: wrap;
  }
`;