import React from 'react';
import H3 from '../../../Text/H3';
import Column from '../../../Column';
import {
  Container, Section, Details,
  Title, Text, HowToBuy, HowToBuySection,
  HowToBuyTitle, HowToBuyContent, HowToBuySplitContainer,
  HowToBuySplitLine, HowToBuySplitCenter,
} from './styled';

function ItemsDisplay(props) {
  const {
    story, alterations, history,
  } = props;
  return (
    <Container>
      <Section>
        <Title>Details:</Title>
        <Details>
          {alterations && <Column style={{ width: '100%', marginBottom: 30 }}>
            <H3 style={{ textAlign:'center' }}>Alterations:</H3>
            <Text>{alterations}</Text>
          </Column>}
          {history && <Column style={{ width: '100%' }}>
            <H3>Preservation History:</H3>
            <Text>{history}</Text>
          </Column>}
        </Details>
      </Section>
      {story && <Section>
        <Title>The Story:</Title>
        <Text style={{ padding: '0 3.125vw' }}>{story}</Text>
      </Section>}
      <Section>
        <Title>How To Buy:</Title>
        <HowToBuy>
          <HowToBuySection>
            <HowToBuyTitle>Option 1: Buy the dress in an instant!</HowToBuyTitle>
            <HowToBuyContent>Hit the buy now button above and the dress will be yours!</HowToBuyContent>
          </HowToBuySection>
          <HowToBuySplit />
          <HowToBuySection>
            <HowToBuyTitle>Option 2: Send a Message</HowToBuyTitle>
            <HowToBuyContent>Start a conversation with the buyer & discuss the details of the garment.</HowToBuyContent>
          </HowToBuySection>
        </HowToBuy>
      </Section>
    </Container>
  );
}

export default ItemsDisplay;


function HowToBuySplit() {
  return (
    <HowToBuySplitContainer>
      <HowToBuySplitLine />
      <HowToBuySplitCenter>or</HowToBuySplitCenter>
      <HowToBuySplitLine />
    </HowToBuySplitContainer>
  );
}