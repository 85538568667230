import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as utils from '../../../../helpers/Format';
import { addItemToCart } from '../../../../actions/checkoutActions';
import { showMessageModal } from '../../../../actions/itemsActions';
import { makeOffer } from '../../../../actions/offerActions.js';
import Row from '../../../Row';
import BackgroundImage from './Background.svg';
import shopping_cart_icon from './ShoppingCartIcon.svg';
import {
  ItemPicturesContainer, ItemPictureMinized, AddToFavorites, BuyNow, ItemContainer, Background, ItemPicture, ItemDetails, Title, Price, SalePrice, SavePercentage, Description,
} from './styled';

function Section1({
  seller,
  name,
  designer,
  price,
  salePrice,
  neckline,
  color,
  tagSize,
  streetSize,
  silhouette,
  material,
  __id,
  photos,
  addItemToCart,
  showMessageModal,
  sellerId,
  condition,
  makeOffer,
  accountType,
}) {
  const save_percentage = utils.getSavePercentage(price, salePrice);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  return (
    <ItemContainer>
      <Background src={BackgroundImage} />
      <ItemPicturesContainer>
        <ItemPicture src={photos[selectedImageIndex]} />
        <div>
          {
            photos.map((image, index) => <ItemPictureMinized onClick={() => setSelectedImageIndex(index)} src={image} key={index} />)
          }
        </div>
      </ItemPicturesContainer>
      <ItemDetails>
        <Title>{name} {designer}</Title>
        <Price>${utils.formatAmount(price)}</Price>
        <SalePrice>${utils.formatAmount(salePrice)}</SalePrice>
        <SavePercentage>Save {save_percentage}</SavePercentage>
        <Description>
          <div>Silhouette:</div>
          <div>{silhouette}</div>
          <div>Designer:</div>
          <div>{designer}</div>
          <div>Name:</div>
          <div>{name}</div>
          <div>Listing Number:</div>
          <div>{__id}</div>
          <div>Material:</div>
          <div>{material}</div>
          <div>Neckline:</div>
          <div>{neckline}</div>
          <div>Color:</div>
          <div>{color}</div>
          <div>Tag Size:</div>
          <div>{tagSize}</div>
          <div>Street Size:</div>
          <div>{streetSize}</div>
          {sellerId && accountType === 0 && (
            <>
              <div>Boutique Seller #:</div>
              <div>{sellerId}</div>
            </>
          )}
          <div>Quality:</div>
          <div>{condition}</div>
        </Description>

        <Row style={{ marginTop: '1.688vw' }}>
          <AddToFavorites onClick={() => showMessageModal(seller)}>SEND A MESSAGE</AddToFavorites>
          <BuyNow onClick={() => addItemToCart(__id)}>
            <img src={shopping_cart_icon} />
            BUY NOW
          </BuyNow>
          <BuyNow onClick={() => makeOffer(seller, __id)} style={{ marginLeft: 20 }}>MAKE OFFER</BuyNow>
        </Row>
      </ItemDetails>
    </ItemContainer>
  );
}

const mapDispatchToProps = {
  addItemToCart, showMessageModal, makeOffer,
};

export default connect(null, mapDispatchToProps)(Section1);
