import React from 'react';
import Header from './Header';
import ItemDisplay from './ItemDisplay';
import ItemDetails from './ItemDetails';
import Questions from './Questions';
import AboutTheSeller from './AboutTheSeller';
import { Container } from './styled';

function Item(props) {
  return (
    <Container>
      <Header />
      <ItemDisplay {...props} />
      <ItemDetails {...props} />
      <Questions {...props} />
      <AboutTheSeller {...props} />
    </Container>
  );
}

export default Item;
