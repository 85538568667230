import React, { useState } from 'react';
import moment from 'moment';
import Image from '../../../ImageBasic';
import {
  Container, Header, Title,
  SubTitle, Questions, QuestionContainer,
  QuestionHeading, LoadMore,
} from './styled';
import DownArrowSVG from './down.svg';

function Section3({ questions = [] }) {
  const [loadMore, setLoadMore] = useState(false);
  const _questions = questions.filter((_, index) => index < 3 || loadMore);

  if (!_questions.length) {
    return <div style={{ marginBottom: 30 }} />;
  }
  return (
    <Container>
      { questions.length ? <Header>
        <Title>Questions</Title>
        <SubTitle>Questions our members have asked this seller about the product.</SubTitle>
      </Header> : null}
      <Questions>
        {
          _questions.map(({
            name, img, date, question, answer,
          }, index) => (
            <div key={index}>
              <QuestionHeading>
                <img src={img} />
                <div>
                  <span>{name}</span>
                  <span>{moment(date).format('DD MMM YYYY')}</span>
                </div>
              </QuestionHeading>
              <QuestionContainer>
                <span>Q: {question}</span>
                <br/>
                <span>{answer}</span>
              </QuestionContainer>
            </div>
          ))
        }
        <LoadMore onClick={() => setLoadMore(!loadMore)}><span>{loadMore ? 'Hide More' : 'Load More'}&nbsp;</span><Image style={{ marginLeft: 10, transform: loadMore ? 'rotate(180deg)' : '' }} src={DownArrowSVG}/></LoadMore>
      </Questions>
    </Container>
  );
}

export default Section3;
