import React, { useState } from 'react';
import { connect } from 'react-redux';
import NavLogo from '../../../NavLogo';
import StandardButton from '../../../StandardButton';
import LoginFirst from '../SendAMessage/LoginFirst';
import { formatNumberInput } from '../../../../helpers/Format/numbers';
import { hideModal, showModal } from '../../../../actions/modalActions';
import Loader from '../../../Loader';
import {
  Container, Title, Body, Input, SubTitle,
} from './styled';

function MakeOffer({
  onSubmit, user , hideModal, showModal,
}) {
  const [offer, setOffer] = useState(0);

  if (!user || user && user.isAnonymous) {
    return <LoginFirst title="Log In First to Make A Offer" />;
  }
  return (
    <Container>
      <NavLogo style={{ color: 'black', marginTop: '7.563vw' }} color="#000000" />
      <Body>
        <Title>Enter Your Offer</Title>
        <Input value={`$${offer}`} onChange={(e) => setOffer(formatNumberInput(e.target.value || 0))} />
        <StandardButton style={{ marginBottom: '5.188vw', marginTop: '1.625vw' }} label="Make Offer" onClick={() => {
          hideModal();
          onSubmit(offer).then((res) => {
            if (res) {
              showModal(
                <Container>
                  <NavLogo style={{ color: 'black', marginTop: '7.563vw' }} color="#000000" />
                  <Body>
                    <Title>Offer Sent!</Title>
                    <SubTitle>
                  Check your inbox to see the status
                    </SubTitle>
                    <StandardButton style={{ marginBottom: '5.188vw', marginTop: '1.625vw' }} label="Keep Shopping" onClick={() => hideModal()} />
                  </Body>
                </Container>
              );
            }
          });
        }} />
      </Body>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });
const mapDispatchToProps = { hideModal, showModal };

export default connect(mapStateToProps, mapDispatchToProps)(MakeOffer);
