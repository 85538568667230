import styled from 'styled-components';

export const Container = styled.div`
  width: 79.81vw;
  background-color: #F8F2F2;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
  margin: 0 auto;
  margin-top: 3.562vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-family: BigCaslon-Medium;
  font-size: 32px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
`;

export const Input = styled.input`
  font-family: sofia-pro, sans-serif;
  font-size: 48px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 22px;
  border-bottom: 1px solid #124C56;
  background-color: transparent;
  --webkit-appearance: none;
  text-align: center;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-family: Helvetica;
  font-size: 21px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 29px;
  margin: 42px 0;
`;
