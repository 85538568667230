import React from 'react';
import { Label, Container } from './styled';

function EmbeddedButton({ label, onClick }) {
  return (
    <Container onClick={onClick}>
      <Label>{label}</Label>
    </Container>
  );
}

export default EmbeddedButton;
