import React from 'react';
import { navigate } from '@reach/router';
import { Container, Label } from './styled';
const menu_items = [{ label: 'Home', url: '/' }, { label: 'Shop Categories', url: '/shop' }, { label: 'Dress', url: '' }];

function Header() {
  return (
    <Container>
      {
        menu_items.map(({ label, url }, index) => <Label onClick={() => navigate(url)} key={index}>{label}</Label>)
      }
    </Container>
  );
}

export default Header;
