import React from 'react';
import Header from './Header';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import { Container } from './styled';

function Item(props) {
  return (
    <Container>
      <Header />
      <Section1 {...props} />
      <Section2 {...props} />
      <Section3 {...props} />
      <Section4 {...props} />
    </Container>
  );
}

export default Item;
