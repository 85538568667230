import styled from 'styled-components';

export const Container = styled.div`
  height: 5.938vw;
  background-color: white;
  padding-left: 13.50vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Label = styled.div`
  cursor: pointer;
  font-family: Lato-Medium;
  font-size: 0.8750vw;
  color: #A3A5AD;
  letter-spacing: 0.35px;
  line-height: 24px;
  margin: 0 10px;
  :nth-child(even) {
    margin: 0;
  }
  :nth-child(even)::before {
    margin-right:10px;
    content: '—';
  }
  :nth-child(even)::after {
    margin-left: 10px;
    content: '—';
  }
`;