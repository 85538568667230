import styled from 'styled-components';

export const ItemContainer = styled.div`
  position: relative;
  width: 100%;
  height: 48.44vw;
  display: flex;
`;

export const Background = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: -1;
`;

export const ItemPicture = styled.img`
  width: 33.25vw;
  height: 33.13vw;
  object-fit: contain;
  margin-left: 13.50vw;
  margin-top: 2.625vw;
  margin-right: 10.44vw;
  margin-bottom: 1.000vw;
`;

export const ItemDetails = styled.div`
    margin-top: 5.375vw;
    flex: 1;
`;
export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 2.375vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 2.875vw;
  margin-bottom: 1.500vw;
`;
export const Price = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.125vw;
  color: #6B969D;
  letter-spacing: 0.95px;
  line-height: 2.875vw;
  text-decoration: line-through;
  margin-bottom: 0.3125vw;
`;
export const SalePrice = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 2.375vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 2.875vw;
`;
export const SavePercentage = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.125vw;
  color: #666EE8;
  letter-spacing: 0.95px;
  line-height: 2.875vw;
  margin-top: 0.2500vw;
`;
export const Description = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.000vw;
  color: #737680;
  letter-spacing: 0.4px;
  line-height: 1.750vw;
  > {
    white-space: nowrap;
  }
`;

export const BuyNow = styled.div`
  margin-left: 20px;
  background-color: #FE95A2;
  height: 4.000vw;
  width: 11.81vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #FFFFFF;
  letter-spacing: 0.1250vw;
  line-height: 1.000vw;
  cursor: pointer;
  img {
    margin-right: 0.8750vw;
  }
`;

export const AddToFavorites = styled.div`
  background-color: white;
  border: 5px solid #FE95A2;
  height: calc(4.000vw - 10px);
  width: 11.81vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #FE95A2;
  letter-spacing: 0.1250vw;
  line-height: 1.000vw;
  cursor: pointer;
`;

export const ItemPictureMinized = styled.img`
  cursor: pointer;
  width: 4.250vw;
  height: 4.250vw;
  background-color: #D5D2CD;
  margin-right: 1.000vw;
  margin-bottom: 1.000vw;
  object-fit: contain;
`;

export const ItemPicturesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  > div {
    width: 33.25vw;
    margin-left: 13.69vw;
    display: flex;
    flex-wrap: wrap;
  }
`;