import styled from 'styled-components';

export const Container = styled.div`
  background-color: #F8F4F4;
  padding-bottom: 15.63vw;
  > div:first-child {
    display: flex;
    margin: 0 5.938vw;
    margin-top: 15.94vw;
    flex-direction: column;
    > img {
      height: 300px;
      object-fit: contain;
      margin-bottom: 14.37vw;
      margin-top: 10.00vw;
    }
  }
`;

export const Button = styled.div`
margin-top: 12.19vw;
  > div {
    font-family: sofia-pro, sans-serif;
  font-weight: 500;
    font-size: 7.187vw;
    color: #464855;
    letter-spacing: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 8.750vw;
    height: 5.625vw;
    margin-top: 5px;
    margin-left: 10px
  }
`;
export const Heading = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 28px;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 3.125vw;
`;
export const SellerName = styled.div`
  div:nth-child(1) {
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-size: 40px;
    color: #343538;
    letter-spacing: 0.95px;
    line-height: 38px;
  }
  div:nth-child(2) {
    font-family: sofia-pro, sans-serif;
  font-weight: 500;
    font-size: 30px;
    color: #343538;
    letter-spacing: 0.95px;
    line-height: 38px;
  }
`;
export const SellerInfo = styled.div`
  font-family: calluna; 
  font-weight: 300;
  font-size: 22px;
  color: #000000;
  letter-spacing: 0;
  line-height: 28px;
`;