import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as utils from '../../../../helpers/Format';
import { addItemToCart } from '../../../../actions/checkoutActions';
import { showMessageModal } from '../../../../actions/itemsActions';
import shopping_cart_icon from './ShoppingCartIcon.svg';
import {
  ItemPicturesContainer, ItemPictureMinized, AddToFavorites, BuyNow, ItemContainer, ItemPicture, Title, Price, SalePrice, SavePercentage, Description,
} from './styled';

function Section1({
  name,
  price,
  salePrice,
  photos,
  id,
  seller,
  addItemToCart,
  showMessageModal,
}) {
  const save_percentage = utils.getSavePercentage(price, salePrice);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  return (
    <ItemContainer>
      <Title>{name}</Title>
      <Price>${utils.formatAmount(price)}</Price>
      <SalePrice>${utils.formatAmount(salePrice)}</SalePrice>
      <SavePercentage>Save {save_percentage}</SavePercentage>
      <ItemPicturesContainer>
        <ItemPicture src={photos[selectedPhotoIndex]} />
        <div>
          {
            photos.map((image, index) => <ItemPictureMinized src={image} onClick={() => setSelectedPhotoIndex(index)} key={index} />)
          }
        </div>
      </ItemPicturesContainer>
      <div style={{
        display: 'flex',
        marginTop: '12.19vw',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
        <BuyNow onClick={() => addItemToCart(id)}>
          <img src={shopping_cart_icon} />
          BUY NOW
        </BuyNow>
        <AddToFavorites onClick={() => showMessageModal(seller)}>SEND A MESSAGE</AddToFavorites>
      </div>
    </ItemContainer>
  );
}

const mapDispatchToProps = { addItemToCart, showMessageModal };

export default connect(null, mapDispatchToProps)(Section1);
