import React, {
  useState, useCallback, useEffect,
} from 'react';
import { navigate } from '@reach/router';
import Row from '../../../Row';
import Column from '../../../Column';
import Image from '../../../ImageBasic';
import { getDocument } from '../../../../helpers/Firebase';
import {
  Container, Location, Title, AccountType,
  Story, ViewProfile,
} from './styled';
import ArrowSVG from './arrow.svg';

function Section4({ seller: selleruid }) {
  const [seller, setSeller] = useState({});

  const getSeller = useCallback(async () => {
    const _seller = await getDocument(`sellers/${selleruid}`);
    setSeller(_seller);
  }, [selleruid]);

  useEffect(() => {
    getSeller();
  }, [getSeller]);

  const {
    profile: {
      firstName, lastName, aboutMe, avatar,
    } = {}, address: { city, state } = {},
    sellerId, details,
  } = seller;
  const accountType = details ? 0 : 1;
  return (
    <Container>
      <Row>
        <Image style={{
          height: 400, width: 'auto', objectFit:'contain',
        }} src={avatar}/>
        <Column style={{ marginLeft: 63, justifyContent: 'flex-start' }}>
          <Title>About The {accountType === 0 ? 'Boutique' : 'Seller'}</Title>
          <AccountType>{accountType === 0 ? details.name : `${firstName} ${lastName}`}</AccountType>
          <Story>{aboutMe}</Story>
          <Column style={{ marginTop: 28 }}>
            <Location>{city}, {state}</Location>
            { accountType === 0 && <Row>
              <ViewProfile onClick={() => navigate(`/sellers/${sellerId}`)}>View Boutique Profile</ViewProfile>
              <Image src={ArrowSVG}/>
            </Row> }
          </Column>
        </Column>
      </Row>
    </Container>
  );
}

export default Section4;
