import React, {
  useState, useEffect, useCallback,
} from 'react';
import { navigate } from '@reach/router';
import { getDocument } from '../../../../helpers/Firebase';
import {
  Container, Button, Heading, SellerName, SellerInfo,
} from './styled';
import arrow_icon from './arrow_icon.svg';

function Section4({ seller: selleruid }) {
  const [seller, setSeller] = useState({});

  const getSeller = useCallback(async () => {
    const _seller = await getDocument(`sellers/${selleruid}`);
    setSeller(_seller);
  }, [selleruid]);

  useEffect(() => {
    getSeller();
  }, [getSeller]);

  const {
    profile: {
      firstName, lastName, aboutMe, avatar,
    } = {}, address: { city, state } = {}, sellerId,
    details,
  } = seller;
  const accountType = details ? 0 : 1;
  return (
    <Container>
      <div>
        <Heading>About The Seller</Heading>
        <SellerName>
          <div>{accountType === 0 ? details.name : `${firstName} ${lastName}`}</div>
          <div>{city}, {state}</div>
        </SellerName>
        <img src={avatar} />
        <SellerInfo>{aboutMe}</SellerInfo>
        { accountType === 0 && <Button>
          <div onClick={() => navigate(`/sellers/${sellerId}`)}>View Seller Profile</div>
          <img src={arrow_icon} />
        </Button>}
      </div>
    </Container>
  );
}

export default Section4;
