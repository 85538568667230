import React from 'react';
import * as apiHelpers from '../helpers/API';
import MakeOfferInput from '../components/Desktop/Item/MakeOffer/';
import { showModal } from './modalActions';
import { setLoading } from './loadingActions';

export function makeOffer(accountId, listingId) {
  return (dispatch, getState) => {
    const { accountType, user } = getState().userReducer;
    const { uid } = user || {};

    if (accountId === uid) {
      return alert('You cannot make an offer to yourself');
    }

    try {
      dispatch(showModal(<MakeOfferInput onSubmit={ (offerAmount) => new Promise((resolve) => {
        if (!offerAmount) {
          return alert('You must enter an amount to make an offer.');
        }
        dispatch(setLoading(true));
        apiHelpers.makeOffer(listingId, offerAmount, accountId, accountType).then(() => {
          dispatch(setLoading(false));
          resolve(true);
        }).catch(alert);
      })} />));
    } catch (e) {
      alert(e.message || e);
    }
  };
}