import styled from 'styled-components';

export const Container = styled.div`
  height: 22.81vw;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  font-family: Lato-Medium;
  font-size: 4.375vw;
  color: #A3A5AD;
  letter-spacing: 0.35px;
  line-height: 24px;
  margin: 0 10px;
  :nth-child(even) {
    margin: 0;
  }
  :nth-child(even)::before {
    margin-right:10px;
    content: '—';
  }
  :nth-child(even)::after {
    margin-left: 10px;
    content: '—';
  }
  :last-child {
    color: #737680;
  }
`;