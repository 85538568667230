import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
`;

export const Section = styled.div`
  border-right: 1px solid #D8D8D8;
  flex: 1;
`;

export const HowToBuy = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin: auto;
`;

export const HowToBuySection = styled.div`
  display: flex;
  flex-direction: column;
  :first-child {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  :last-child {
    margin-top: 30px;
  }
`;

export const HowToBuyTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #6B969D;
  letter-spacing: 0.4px;
`;

export const HowToBuyContent = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #464855;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-top: 5px;
`;

export const HowToBuySplitContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HowToBuySplitLine = styled.div`
  width: 100%;
  height: 1px;
  background-color:#D8C4C2;
  margin: 0 5px;
`;

export const HowToBuySplitCenter = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: white;
  background-color:#D8C4C2;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 0 25px;
  > {
    white-space: nowrap;
  }
  > :nth-child(odd) {
    justify-self: flex-start;
    text-align: left;
  }
  > :nth-child(even) {
    justify-self: flex-end;
    text-align: right;
  }
`;


export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 38px;
  color: #343538;
  letter-spacing: 0.95px;
  text-align: center;
  line-height: 46px;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  flex: 1;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #737680;
  letter-spacing: 0.4px;
  line-height: 28px;
`;