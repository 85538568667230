import React, { useState } from 'react';
import moment from 'moment';
import Image from '../../../ImageBasic';
import EmbeddedButton from '../../../EmbeddedButton';
import {
  Container, Header, Title,
  SubTitle, QuestionsItems, QuestionContainer,
  HeaderSection, LoadMore,
} from './styled';
import DownArrowSVG from './down.svg';

function Questions({
  showQuestionModal, questions = [], id,
}) {
  const [loadMore, setLoadMore] = useState(false);
  const _questions = questions.filter((_, index) => index < 3 || loadMore);

  if (!_questions.length) {
    return <div style={{ marginBottom: 30 }} />;
  }
  return (
    <Container>
      <Header>
        <HeaderSection>
          <Title>Questions</Title>
          <SubTitle>Questions our members have asked this seller about the product.</SubTitle>
        </HeaderSection>
        <HeaderSection>
          <EmbeddedButton onClick={() => showQuestionModal(id)} label="Ask a Question" />
        </HeaderSection>
      </Header>
      <QuestionsItems>
        {
          _questions.map(({
            name, img, date, question, answer,
          }, index) => (
            <div key={index}>
              <img src={img} />
              <QuestionContainer>
                <span>{name}</span>
                <span>{moment(date).format('DD MMM YYYY')}</span>
                <span>Q: {question}</span>
                <span>{answer}</span>
              </QuestionContainer>
            </div>
          ))
        }
        <LoadMore onClick={() => setLoadMore(!loadMore)}><span>{loadMore ? 'Hide More' : 'Load More'}&nbsp;</span><Image style={{
          height: 20, width: 20, marginLeft: 10, transform: loadMore ? 'rotate(180deg)' : '',
        }} src={DownArrowSVG}/></LoadMore>
      </QuestionsItems>
    </Container>
  );
}

export default Questions;
