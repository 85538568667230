import styled from 'styled-components';

export const Container = styled.div`
  padding: 8.313vw 13.75vw 5.500vw 13.75vw;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 2.375vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 2.875vw;
  margin-bottom: 0.6250vw;
`;

export const SubTitle = styled.div`
  font-family: Lato-Regular;
  font-size: 1.000vw;
  color: #737680;
  letter-spacing: 0.4px;
  line-height: 1.750vw;
`;

export const QuestionsItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.813vw;
  div {
    margin-bottom: 1.563vw;
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 16px;
    color: #737680;
    letter-spacing: 0.4px;
    line-height: 28px;
    display: flex;
    :last-child {
      margin-bottom: 0;
    }
    img {
      object-fit: contain;
      width: 5.563vw;
      height: 4.125vw;
    }
  }
`;

export const QuestionContainer = styled.div`
  margin-left: 2.750vw;
  display: flex;
  flex-direction: column;
  span:nth-child(1) {
    font-family: Lato-Semibold;
    font-size: 1.000vw;
    color: #343538;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  span:nth-child(2) {
    font-family: Lato-Regular;
    font-size: 0.8750vw;
    color: #A3A5AD;
    letter-spacing: 0.35px;
    line-height: 24px;
    margin-bottom: 0.3750vw;
  }
  span:nth-child(4) {
    width: 63.25vw;
  }
`;


export const LoadMore = styled.div`
  font-family: Lato-Regular;
  font-size: 16px;
  color: #6B969D;
  letter-spacing: 0.4px;
  text-align: center;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  cursor: pointer;
`;