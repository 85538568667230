import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5.938vw 100px;
  margin-bottom: 96px;
`;
export const AccountType = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 38px;
  color: #124C56;
  letter-spacing: 0.58px;
  line-height: 38px;
  margin-bottom: 15px;
`;
export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0.95px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 27px;
`;
export const Location = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #124C56;
  letter-spacing: 0.58px;
  line-height: 38px;
`;
export const Story = styled.div`
  font-family: calluna; 
  font-weight: 300;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 30px;
  margin-right: 50%;
`;

export const ViewProfile = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 23px;
  color: #EAAFB7;
  letter-spacing: 0;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
`;