import styled from 'styled-components';

export const Container = styled.div`
  margin: 60px 30px;
  border: 1px solid #7DBEC8;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: SofiaProMedium;
  font-size: 38px;
  color: #124C56;
  letter-spacing: 0.95px;
  line-height: 46px;
`;

export const SubTitle = styled.div`
  font-family: Lato-Regular;
  font-size: 16px;
  color: #6B969D;
  letter-spacing: 0.4px;
  line-height: 28px;
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  > div {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const QuestionHeading = styled.div`
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    margin-left: 6.250vw;
  }
  span:nth-child(1) {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #124C56;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  span:nth-child(2) {
    font-family: Lato-Regular;
    font-size: 14px;
    color: #6B969D;
    letter-spacing: 0.35px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  img {
    object-fit: contain;
    width: 66px;
    height: 66px;
    margin-bottom: 15px;
    border-radius: 50%;
  }
`;

export const QuestionContainer = styled.div`
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  color: #124C56;
  letter-spacing: 0.4px;
  line-height: 28px;
  span:nth-child(1) {
    font-weight: 800;
  }
`;

export const LoadMore = styled.div`
  font-family: Lato-Regular;
  font-size: 16px;
  color: #6B969D;
  letter-spacing: 0.4px;
  text-align: center;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  cursor: pointer;
`;